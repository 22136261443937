td,
th {
  border: 1px solid #000;
  padding: 0.75rem;
  text-align: left;
}
th {
  font-weight: 700;
  white-space: nowrap;
  background: #000;
  color: #fff;
}
table {
  border-collapse: collapse;
  background: rgba(255, 255, 255, 0.4);
}
tbody tr:hover {
  background-color: beige;
  cursor: pointer;
}
